<template>
  <FullPage :title="$t('passwordRecoverView.title')">
    <ValidationObserver v-slot="{ handleSubmit, invalid }">
      <el-form
        ref="form"
        label-position="top"
        :model="formObject"
      >
        <FormItem
          rules="required|email"
          :label="$t('passwordRecoverView.emailInput')"
        >
          <el-input
            v-model="formObject.email"
            autocomplete="off"
          />
        </FormItem>
        <FormButtons>
          <el-button
            type="primary"
            native-type="submit"
            :disabled="invalid"
            @click.prevent="handleSubmit(recoverPassword)"
          >
            {{ $t('passwordRecoverView.submitButton') }}
          </el-button>
        </FormButtons>
      </el-form>
    </ValidationObserver>
  </FullPage>
</template>

<script>
import FormItem from '@/components/FormItem.vue';
import FormButtons from '@/components/FormButtons.vue';
import FullPage from '@/components/FullPage.vue';
import usersApi from '../api/users';

export default {
  name: 'PasswordRecover',
  components: {
    FormItem,
    FormButtons,
    FullPage,
  },
  data() {
    return {
      formObject: {
        email: '',
      },
    };
  },
  methods: {
    async recoverPassword() {
      try {
        await usersApi.recoverPassword(this.formObject);
        this.$router.push({ name: 'Login' });
        this.$notify({ type: 'success', message: this.$t('passwordRecoverView.passwordRecoveredMessage') });
      } catch (error) {
        this.$notify({ type: 'error', message: error.response.data.error.message });
      }
    },
  },
};
</script>

<style lang="scss">
.password-recover-view-content {
  height: 100vh;
}
</style>
